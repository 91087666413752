<template>
<div class="user-index">
  <h2 class="content-title" v-if="type == 'set'">完善机构信息</h2>
  <el-page-header @back="goBack" :content="'机构信息'" v-if="type == 'check'"></el-page-header>
  <div class="content" v-loading="loading" v-if="type == 'set'">
    <p style="color: #f56c6c; margin-bottom: 20px; font-size: 14px;">温馨提示：需要您完善机构信息，方可进行机构自评</p>
    <el-form label-position="right" label-width="170px" class="form">
      <el-form-item label="机构所在省市区：">
        <el-select class="select" @change="getCity" v-model="ruleForm.value1" placeholder="请选择所属省">
          <el-option v-for="item in options1" :key="item.code" :label="item.text" :value="item.code">
          </el-option>
        </el-select>
        <el-select class="select" @change="getArea" v-model="ruleForm.value2" placeholder="请选择所属市">
          <el-option v-for="item in options2" :key="item.code" :label="item.text" :value="item.code">
          </el-option>
        </el-select>
        <el-select class="select" v-model="ruleForm.value3" placeholder="请选择所属区/县">
          <el-option v-for="item in options3" :key="item.code" :label="item.text" :value="item.code">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="机构详细地址：">
        <el-input v-model="ruleForm.address" placeholder="输入机构详细地址信息"></el-input>
      </el-form-item>
      <el-form-item label="机构成立时间：">
        <el-select class="input" v-model="ruleForm.start_year" placeholder="请选择">
          <el-option v-for="item in year_arr" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="机构举办性质：">
        <el-select class="input" v-model="ruleForm.type" placeholder="请选择">
          <el-option v-for="item in type_arr" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="举办者身份：">
        <el-select class="input" v-model="ruleForm.user_type" placeholder="请选择">
          <el-option v-for="item in user_type_arr" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="最大可收托婴幼儿总数：">
        <el-select class="input" v-model="ruleForm.child_num" placeholder="请选择">
          <el-option v-for="item in child_num_arr" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="开设的班型：">
        <el-select class="input" v-model="ruleForm.class_type" multiple placeholder="请选择">
          <el-option v-for="item in class_type_arr" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="托育幼儿的最小月龄">
        <el-input-number v-model="ruleForm.min_age"></el-input-number>
      </el-form-item>
      <el-form-item label="上传机构视频：">
        <div class="upload">
          <h3>1）请上传托育机构周边环境视频<span>（可上传1个视频，支持MP4、MOV格式）</span></h3>
          <ul class="el-upload-list el-upload-list--picture-card" v-if="ruleForm.video1">
            <li class="el-upload-list__item is-success">
              <div style="display: flex;align-items: center;justify-content: center;height:100%;" class="video_bg">
                <video :src="ruleForm.video1.url" style="width: 100%"></video>
                <span class="el-upload-list__item-actions">
                  <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(ruleForm.video1)">
                    <i class="el-icon-video-play"></i>
                  </span>
                  <span class="el-upload-list__item-delete" @click="ObsDelete('video1')">
                    <i class="el-icon-delete"></i>
                  </span>
                </span>
              </div>
            </li>
          </ul>
          <ul class="el-upload-list el-upload-list--picture-card" v-show="uploadShow1">
            <li class="el-upload-list__item upload-item" v-loading="true" :element-loading-text="loadingText1" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.5)">
            </li>
          </ul>
          <el-upload v-if="!ruleForm.video1 && !uploadShow1" action="#" list-type="picture-card" accept="video/mp4,video/x-m4v,video/*" :show-file-list="false" :http-request="(e)=>{ObsUpload(e, 'video1',)}">
            <i class="el-icon-plus"></i>
          </el-upload>
        </div>
        <div class="upload">
          <h3>2）请上传托育机构整体外观视频（如园门、建筑物等）<span>（可上传1个视频，支持MP4、MOV格式）</span></h3>
          <ul class="el-upload-list el-upload-list--picture-card" v-if="ruleForm.video2">
            <li class="el-upload-list__item is-success">
              <div style="display: flex;align-items: center;justify-content: center;height:100%;" class="video_bg">
                <video :src="ruleForm.video2.url" style="width: 100%"></video>
                <span class="el-upload-list__item-actions">
                  <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(ruleForm.video2)">
                    <i class="el-icon-video-play"></i>
                  </span>
                  <span class="el-upload-list__item-delete" @click="ObsDelete('video2')">
                    <i class="el-icon-delete"></i>
                  </span>
                </span>
              </div>
            </li>
          </ul>
          <ul class="el-upload-list el-upload-list--picture-card" v-show="uploadShow2">
            <li class="el-upload-list__item upload-item" v-loading="true" :element-loading-text="loadingText2" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.5)">
            </li>
          </ul>
          <el-upload v-if="!ruleForm.video2 && !uploadShow2" action="#" list-type="picture-card" accept="video/mp4,video/x-m4v,video/*" :show-file-list="false" :http-request="(e)=>{ObsUpload(e, 'video2',)}">
            <i class="el-icon-plus"></i>
          </el-upload>
        </div>
        <div class="upload">
          <h3>3）请上传托育机构内部环境视频<span>（可上传1个视频，支持MP4、MOV格式）</span></h3>
          <ul class="el-upload-list el-upload-list--picture-card" v-if="ruleForm.video3">
            <li class="el-upload-list__item is-success">
              <div style="display: flex;align-items: center;justify-content: center;height:100%;" class="video_bg">
                <video :src="ruleForm.video3.url" style="width: 100%"></video>
                <span class="el-upload-list__item-actions">
                  <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(ruleForm.video3)">
                    <i class="el-icon-video-play"></i>
                  </span>
                  <span class="el-upload-list__item-delete" @click="ObsDelete('video3')">
                    <i class="el-icon-delete"></i>
                  </span>
                </span>
              </div>
            </li>
          </ul>
          <ul class="el-upload-list el-upload-list--picture-card" v-show="uploadShow3">
            <li class="el-upload-list__item upload-item" v-loading="true" :element-loading-text="loadingText3" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.5)">
            </li>
          </ul>
          <el-upload v-if="!ruleForm.video3 && !uploadShow3" action="#" list-type="picture-card" accept="video/mp4,video/x-m4v,video/*" :show-file-list="false" :http-request="(e)=>{ObsUpload(e, 'video3',)}">
            <i class="el-icon-plus"></i>
          </el-upload>
        </div>
        <div class="upload">
          <h3>4）请上传托育机构某个班级的布局和环境（正在进行师幼互动）的视频<span>（可上传1个视频，支持MP4、MOV格式）</span></h3>
          <ul class="el-upload-list el-upload-list--picture-card" v-if="ruleForm.video4">
            <li class="el-upload-list__item is-success">
              <div style="display: flex;align-items: center;justify-content: center;height:100%;" class="video_bg">
                <video :src="ruleForm.video4.url" style="width: 100%"></video>
                <span class="el-upload-list__item-actions">
                  <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(ruleForm.video4)">
                    <i class="el-icon-video-play"></i>
                  </span>
                  <span class="el-upload-list__item-delete" @click="ObsDelete('video4')">
                    <i class="el-icon-delete"></i>
                  </span>
                </span>
              </div>
            </li>
          </ul>
          <ul class="el-upload-list el-upload-list--picture-card" v-show="uploadShow4">
            <li class="el-upload-list__item upload-item" v-loading="true" :element-loading-text="loadingText4" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.5)">
            </li>
          </ul>
          <el-upload v-if="!ruleForm.video4 && !uploadShow4" action="#" list-type="picture-card" accept="video/mp4,video/x-m4v,video/*" :show-file-list="false" :http-request="(e)=>{ObsUpload(e, 'video4',)}">
            <i class="el-icon-plus"></i>
          </el-upload>
        </div>
      </el-form-item>
      <el-form-item>
        <el-button style="width: 120px;margin-right: 30px;" v-if="!is_organ_check" type="primary" @click="saveForm" :loading="submitLoading2">保存</el-button>
        <el-button style="width: 120px;" type="primary" @click="submitForm" :loading="submitLoading" :disabled="canSubmit">提交</el-button>
      </el-form-item>
    </el-form>
  </div>
  <div class="content" v-loading="loading" v-if="type == 'check'">
    <el-descriptions :column="1">
      <el-descriptions-item label="机构编号">{{organization_data.organ_code}}</el-descriptions-item>
      <el-descriptions-item label="机构名称">{{organization_data.name}}</el-descriptions-item>
      <el-descriptions-item label="机构所在省市区">{{organization_data.province_name + '-' + organization_data.city_name + '-' + organization_data.area_name}}</el-descriptions-item>
      <el-descriptions-item label="详细地址">{{organization_data.address}}</el-descriptions-item>
      <el-descriptions-item label="机构成立时间">{{organization_data.start_year}}年</el-descriptions-item>
      <el-descriptions-item label="机构举办性质">{{organization_data.type_name}}</el-descriptions-item>
      <el-descriptions-item label="最大可收托婴幼儿总数">{{organization_data.child_num_name}}</el-descriptions-item>
      <el-descriptions-item label="开设的班型">{{organization_data.class_type_name}}</el-descriptions-item>
      <el-descriptions-item label="托育幼儿的最小年龄">{{organization_data.min_age}}月龄</el-descriptions-item>
      <el-descriptions-item label="上传机构视频">
        <div class="video-list">
          <div>
            <p>周边环境视频</p>
            <ul class="el-upload-list el-upload-list--picture-card">
              <li class="el-upload-list__item is-success">
                <div style="display: flex;align-items: center;justify-content: center;height:100%;" class="video_bg">
                  <video :src="ruleForm.video1.url" style="width: 100%"></video>
                  <span class="el-upload-list__item-actions">
                    <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(ruleForm.video1)">
                      <i class="el-icon-video-play"></i>
                    </span>
                  </span>
                </div>
              </li>
            </ul>
          </div>
          <div>
            <p>整体外观视频</p>
            <ul class="el-upload-list el-upload-list--picture-card">
              <li class="el-upload-list__item is-success">
                <div style="display: flex;align-items: center;justify-content: center;height:100%;" class="video_bg">
                  <video :src="ruleForm.video2.url" style="width: 100%"></video>
                  <span class="el-upload-list__item-actions">
                    <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(ruleForm.video2)">
                      <i class="el-icon-video-play"></i>
                    </span>
                  </span>
                </div>
              </li>
            </ul>
          </div>
          <div>
            <p>内部环境视频</p>
            <ul class="el-upload-list el-upload-list--picture-card">
              <li class="el-upload-list__item is-success">
                <div style="display: flex;align-items: center;justify-content: center;height:100%;" class="video_bg">
                  <video :src="ruleForm.video3.url" style="width: 100%"></video>
                  <span class="el-upload-list__item-actions">
                    <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(ruleForm.video3)">
                      <i class="el-icon-video-play"></i>
                    </span>
                  </span>
                </div>
              </li>
            </ul>
          </div>
          <div>
            <p>班级布局视频</p>
            <ul class="el-upload-list el-upload-list--picture-card">
              <li class="el-upload-list__item is-success">
                <div style="display: flex;align-items: center;justify-content: center;height:100%;" class="video_bg">
                  <video :src="ruleForm.video4.url" style="width: 100%"></video>
                  <span class="el-upload-list__item-actions">
                    <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(ruleForm.video4)">
                      <i class="el-icon-video-play"></i>
                    </span>
                  </span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </el-descriptions-item>
    </el-descriptions>
    <el-form label-position="right" label-width="170px" class="form">
      <el-form-item>
        <el-button style="width: 220px;" type="primary" @click="type = 'set'">修改</el-button>
      </el-form-item>
    </el-form>
  </div>
  <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="70%" @close="dialogVideoUrl = ''">
    <video class="video" controls v-if="dialogVideoUrl" width="100%" :src="dialogVideoUrl" alt="" />
  </el-dialog>
</div>
</template>

<script>
import obs from "@/utils/obs.js";

export default {
  name: "inspect-index",
  data() {
    return {
      type: '',
      id: "",
      title: "",
      role: '',
      loading: false,
      submitLoading2: false,
      submitLoading: false,
      ruleForm: {
        id: '',
        date: '',
        min_age: 0,
        value1: "",
        value2: "",
        value3: "",
        video1: '',
        video2: '',
        video3: '',
        video4: '',
        class_type: [],
        child_num: '',
        user_type: '',
        type: '',
        start_year: '',
        address: ''
      },
      organization_data: '',
      uploadShow1: false,
      uploadShow2: false,
      uploadShow3: false,
      uploadShow4: false,
      options1: [],
      options2: [],
      options3: [],
      dialogVisible: false,
      dialogTitle: '',
      class_type_arr: [],
      child_num_arr: [],
      user_type_arr: [],
      year_arr: [],
      type_arr: [],
      dialogVideoUrl: '',
      rules: {
        name: [{
          required: true,
          message: "请输入机构名称",
          trigger: "blur"
        }],
        address: [{
          required: true,
          message: "请输入机构详细地址信",
          trigger: "blur"
        }, ],
      },
      organization_id: 0,
      is_organ_check: 0,
      loadingText1: '',
      loadingText2: '',
      loadingText3: '',
      loadingText4: ''
    };
  },
  mounted() {
    this.is_organ_check = localStorage.getItem("is_organ_check") * 1;
    let r = this.$route;
    let t = r.params.type;
    this.type = t;
    let id = r.query.id;
    if (t == "set") {
      this.title = "完善机构信息";
    } else if (t == "edit") {
      this.title = "修改机构信息";
      this.id = id;
    } else if (t == "check") {
      this.title = "机构信息";
      this.id = id;
    }
    this.organization_id = localStorage.getItem('organization_id');
    this.init();
    let year = new Date().getFullYear() + 1;
    for (let index = year; index >= year - 50; index--) {
      this.year_arr.push({
        lable: index + '',
        value: index + ''
      })
    }
    this.role = localStorage.getItem('role');
  },
  computed: {
    canSubmit() {
      return !(
        this.ruleForm.value1 &&
        this.ruleForm.value2 &&
        this.ruleForm.value3 &&
        this.ruleForm.start_year &&
        this.ruleForm.type &&
        this.ruleForm.user_type &&
        this.ruleForm.child_num &&
        this.ruleForm.user_type &&
        this.ruleForm.class_type &&
        this.ruleForm.video1 &&
        this.ruleForm.video2 &&
        this.ruleForm.video3 &&
        this.ruleForm.video4 &&
        this.ruleForm.address)
    },
  },
  methods: {
    uploadding1(e, size) {
      console.log(e,size)
      if (e == 0) {
        return
      }
      let r = (e / size * 10000 / 100).toFixed() + '%';
      this.loadingText1 = '上传 ' + r
    },
    uploadding2(e, size) {
      if (e == 0) {
        return
      }
      let r = (e / size * 10000 / 100).toFixed() + '%';
      this.loadingText2 = '上传 ' + r
    },
    uploadding3(e, size) {
      if (e == 0) {
        return
      }
      let r = (e / size * 10000 / 100).toFixed() + '%';
      this.loadingText3 = '上传 ' + r
    },
    uploadding4(e, size) {
      if (e == 0) {
        return
      }
      let r = (e / size * 10000 / 100).toFixed() + '%';
      this.loadingText4 = '上传 ' + r
    },
    getFileDate() {
      let date = new Date();
      return date.getFullYear() + '_' + (date.getMonth() + 1) + '_' + date.getDate() + '_' + date.getTime()
    },
    async ObsUpload(e, index) {
      let file = e.file;
      console.log(file, index);
      if (!file.file_name) {
        file.file_name = file.name;
      }
      let show = index.replace('video', 'uploadShow');
      this[show] = true;
      let pahtName = `tongliao_organization/organization_${this.organization_id}`;
      let bucketName = 'servicetuoyusaas';
      let fileName = pahtName + '/' + this.getFileDate() + '_' + file.file_name;
      let cb = '';
      if (index == 'video1') {
        cb = this.uploadding1;
      }
      if (index == 'video2') {
        cb = this.uploadding2;
      }
      if (index == 'video3') {
        cb = this.uploadding3;
      }
      if (index == 'video4') {
        cb = this.uploadding4;
      }
      let res = await obs.upload(file, fileName, bucketName, cb);
      if (res == "success") {
        this.$message({
          message: '保存成功',
          type: 'success'
        });;
        try {
          let url =
            "https://servicetuoyusaas.obs.cn-east-3.myhuaweicloud.com/" + fileName;
          // console.log(url);
          this[show] = false;
          this.ruleForm[index] = {
            api_url: fileName,
            file_name: file.file_name,
            url: url
          };
          return true;
        } catch (error) {
          console.log(error);
        }
      }
      console.log(list);
    },
    ObsDelete(index) {
      this.ruleForm[index] = '';
    },

    handlePictureCardPreview(file) {
      this.dialogVideoUrl = file.url;
      this.dialogVisible = true;
      this.dialogTitle = ' 查看';
    },
    async init() {
      this.loading = true;
      let city = await this.$api({
        method: "get",
        url: "/api/province",
      });
      if (city && city.code == 0) {
        this.options1 = city.data;
      }
      let res = await this.$api({
        method: "post",
        url: "/api/organ/organdetial",
        data: {
          id: this.organization_id
        }
      });
      if (res.code == 0) {
        await this.getCity(res.data.province_code + '');
        await this.getArea(res.data.city_code + '');

        this.ruleForm.value1 = res.data.province_code + '';
        this.ruleForm.value2 = res.data.city_code + '';
        this.ruleForm.value3 = res.data.area_code + '';
        this.ruleForm.address = res.data.address || '';
        this.ruleForm.class_type = res.data.class_type ? res.data.class_type.split(',') : [];
        this.ruleForm.child_num = res.data.child_num ? res.data.child_num + '' : '';
        this.ruleForm.user_type = res.data.user_type ? res.data.user_type + '' : '';
        this.ruleForm.type = res.data.type ? res.data.type + '' : '';
        this.ruleForm.min_age = res.data.min_age * 1;
        this.ruleForm.start_year = res.data.start_year ? res.data.start_year + '' : '';
        this.organization_data = res.data;
        if (res.data.environment_url) {
          this.ruleForm.video1 = {
            api_url: res.data.environment_url,
            url: 'https://servicetuoyusaas.obs.cn-east-3.myhuaweicloud.com/' + res.data.environment_url
          };
        }
        if (res.data.outside_url) {
          this.ruleForm.video2 = {
            api_url: res.data.outside_url,
            url: 'https://servicetuoyusaas.obs.cn-east-3.myhuaweicloud.com/' + res.data.outside_url
          };
        }
        if (res.data.inhouse_url) {
          this.ruleForm.video3 = {
            api_url: res.data.inhouse_url,
            url: 'https://servicetuoyusaas.obs.cn-east-3.myhuaweicloud.com/' + res.data.inhouse_url
          };
        }
        if (res.data.act_url) {
          this.ruleForm.video4 = {
            api_url: res.data.act_url,
            url: 'https://servicetuoyusaas.obs.cn-east-3.myhuaweicloud.com/' + res.data.act_url
          };
        }

        for (const key in res.data.class_type_arr) {
          let element = res.data.class_type_arr[key];
          this.class_type_arr.push({
            label: element,
            value: key
          })
        }
        for (const key in res.data.child_num_arr) {
          let element = res.data.child_num_arr[key];
          this.child_num_arr.push({
            label: element,
            value: key
          })
        }
        for (const key in res.data.user_type_arr) {
          let element = res.data.user_type_arr[key];
          this.user_type_arr.push({
            label: element,
            value: key
          })
        }
        for (const key in res.data.type_arr) {
          console.log(key)
          let element = res.data.type_arr[key];
          this.type_arr.push({
            label: element,
            value: key
          })
        }
      } else {
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }
      this.loading = false;
    },
    async getCity(e) {
      this.ruleForm.value2 = '';
      this.ruleForm.value3 = '';
      this.options2 = [];
      this.options3 = [];
      let res = await this.$api({
        method: "get",
        url: "/api/city",
        data: {
          q: e
        }
      });
      if (res.code == 0) {
        this.options2 = res.data;
      } else {
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    async getArea(e) {
      this.ruleForm.value3 = '';
      this.options3 = [];
      let res = await this.$api({
        method: "get",
        url: "/api/area",
        data: {
          q: e
        }
      });
      if (res.code == 0) {
        this.options3 = res.data;
      } else {
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }
    },
    async saveForm() {
      this.$confirm(`此操作将保存当前填写的数据，所有内容填写完成，请点击提交按钮完善机构信息`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          center: true,
          customClass: "customConfirm",
          confirmButtonClass: "confirmButtonClass",
          cancelButtonClass: "cancelButtonClass",
        })
        .then(async () => {
          let res = await this.$api({
            method: "post",
            url: "/api/organ/ownsaveing",
            data: {
              id: this.organization_id,
              province_code: this.ruleForm.value1,
              city_code: this.ruleForm.value2,
              area_code: this.ruleForm.value3,
              start_year: this.ruleForm.start_year,
              type: this.ruleForm.type,
              address: this.ruleForm.address,
              min_age: this.ruleForm.min_age,
              user_type: this.ruleForm.user_type,
              child_num: this.ruleForm.child_num,
              stuser_type: this.ruleForm.user_type,
              class_type: String(this.ruleForm.class_type),
              environment_url: this.ruleForm.video1.api_url,
              outside_url: this.ruleForm.video2.api_url,
              inhouse_url: this.ruleForm.video3.api_url,
              act_url: this.ruleForm.video4.api_url,
            }
          });
          if (res.code == 0) {
            this.$message({
          message: '保存成功',
          type: 'success'
        });;
          } else {
            this.$message({
              message: res.msg,
              type: "warning",
            });
          }
        })
        .catch(() => {});

    },
    async submitForm() {
      this.$confirm(`提交后，可到机构信息进行修改内容`, "完善机构信息", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          center: true,
          customClass: "customConfirm",
          confirmButtonClass: "confirmButtonClass",
          cancelButtonClass: "cancelButtonClass",
        })
        .then(async () => {
          let res = await this.$api({
            method: "post",
            url: "/api/organ/ownsave",
            data: {
              id: this.organization_id,
              province_code: this.ruleForm.value1,
              city_code: this.ruleForm.value2,
              area_code: this.ruleForm.value3,
              start_year: this.ruleForm.start_year,
              type: this.ruleForm.type,
              address: this.ruleForm.address,
              min_age: this.ruleForm.min_age,
              user_type: this.ruleForm.user_type,
              child_num: this.ruleForm.child_num,
              stuser_type: this.ruleForm.user_type,
              class_type: String(this.ruleForm.class_type),
              environment_url: this.ruleForm.video1.api_url,
              outside_url: this.ruleForm.video2.api_url,
              inhouse_url: this.ruleForm.video3.api_url,
              act_url: this.ruleForm.video4.api_url,
            }
          });
          if (res.code == 0) {
            this.$message({
          message: '提交成功',
          type: 'success'
        });;
            localStorage.setItem("is_organ_check", 1);
            this.$router.push('/')
          } else {
            this.$message({
              message: res.msg,
              type: "warning",
            });
          }
        }).catch(() => {})
    },
  },
  components: {
    // HelloWorld
  },
};
</script>

<style lang="scss" scoped>
.user-index {
  overflow: hidden;
  min-width: 960px;
  padding: 10px 4vw;

  .video_bg {
    background: url('../../assets/index/video_bg.png') no-repeat center;
    background-size: 100% 100%;
  }

  .title {
    font-size: 20px;
    font-weight: bold;
    text-align: left;
  }

  .content-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 22px;
    margin: 20px auto 20px;
  }

  .video-list {
    display: flex;
    align-items: center;

    >div {
      display: flex;
      align-content: center;
      justify-content: center;
      flex-direction: column;
      margin-right: 10px;

      >p {
        line-height: 30px;
        text-align: center;
        padding-right: 12px;
      }
    }
  }

  .content {
    margin: 0 auto;
    background: #fff;
    margin-top: 20px;
    min-height: calc(100vh - 220px);
    display: flex;
    padding: 30px 30px;
    flex-direction: column;

    .form {
      width: 700px;
    }

    .input {
      width: 530px;
    }

    .select {
      width: calc((100% - 50px) / 3);
      margin-right: 25px;
    }

    .select:last-of-type {
      margin-right: 0;
    }
  }

  .upload {
    padding-bottom: 10px;
    display: block;
    overflow: hidden;

    .upload-del {
      position: absolute;
      right: -9px;
      top: -11px;
      width: 22px;
      height: 22px;
    }

    .file-list-bg {
      width: 345px;
      background: #ffffff;
      border-radius: 6px;

      .file-item {
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        color: #333;
        font-size: 15px;
        padding: 20px 15px;

        span {
          flex: auto;

          a {
            color: #333;
            font-size: 15px;
            cursor: pointer;
            text-decoration: none;
          }
        }

        i {
          flex: 0 0 20px;
        }
      }
    }

    .upload-group {
      overflow: hidden;
      padding: 0 15px;
      text-align: left;
    }

    h3 {
      font-size: 14px;
      color: #333;
      margin: 8px 0 8px;
      font-weight: normal;
      line-height: 1.6;
    }

    h3 span {
      font-size: 12px;
      color: #666;
      font-weight: normal;
    }
  }

}
</style>

<style lang="scss">
.user-index {
  background: #f3f6fb;

  .video {
    width: 100%;
    height: 450px;
    background: #000;
  }

  .upload-item .el-loading-spinner .el-icon-loading {
    font-size: 20px;
  }

  .upload-item .el-loading-spinner .el-loading-text {
    font-size: 14px;
    text-align: center;
  }

  .el-form-item__content {
    text-align: left;
  }

  .el-form-item {
    margin-bottom: 32px;
  }

  .el-descriptions {
    font-size: 15px;
  }

  .el-descriptions-item__label:not(.is-bordered-label) {
    width: 160px;
    justify-content: flex-end;
    color: #000;
  }

  .el-descriptions :not(.is-bordered) .el-descriptions-item__cell {
    padding-bottom: 22px;
  }

  .el-upload--picture-card,
  .el-upload-list--picture-card .el-upload-list__item {
    flex: 0 0 106px;
    width: 106px;
    height: 106px;
    display: flex;
    float: left;
    margin-right: 12px;
    align-items: center;
    justify-content: center;
    position: relative;
  }
}
</style>
